import { useEffect } from "react";
import { Send } from 'lucide-react'

const Notify = () => {
  return (
    <div className=" flex flex-col items-center justify-center text-center mb-12 mt-4">
      <h1 className="text-2xl font-bold mb-4 text-purple-400">New Telegram Group</h1>
      <p className="text-1xl text-gray-300 mb-4 ">Talk with Developer If Features are not working, Latest Updates & If You Feel Free.</p>
      <a href="https://t.me/WatchAnimeHindi_Official" className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center text-1xl"> Join Now <span className="ml-2 h-5 w-5" /><Send/> </a>
    </div>
  );
};

export default Notify;