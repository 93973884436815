import React from 'react';
import QRCode from '../../images/rezraza@fam.png';
import "../home/NewHome.css";
import { HeartIcon, SmartphoneIcon, InfoIcon, UserIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import NewHome from './HeaderNew';
import FooterNav from './FooterNav';
import GoalBar from './GoalBar'

export default function DarkDonatePage() {
  const donationGoal = 10000; // Goal amount
  const totalDonations = 56; // Current donation amount
 

  const userDonations = [
    { name: "Pritam Roy Chrowdhury", amount: "₹50", message: "No Message" },
    { name: "Rez", amount: "₹2", message: "No Message" },
    { name: "Shrey Vora", amount: "₹1", message: "No Message" },
    { name: "Khushi Sarkar", amount: "₹1", message: "No Message" },
    { name: "Md Ramzan Ansari", amount: "₹1", message: "No Message" },
    { name: "Prabhat Kumar", amount: "₹1", message: "No Message" },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <NewHome />
      <main className="container mx-auto px-4 py-8">

        {/* Use GoalBar Component */}
        <GoalBar totalDonations={totalDonations} donationGoal={donationGoal} />

        <section className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-purple-400">Show your Love</h1> 
          <p className="text-xl text-gray-300 mb-8">Your generosity can make a real impact. Donate today!</p>
          <div className='flex justify-center'>
            {/* <button className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center">
              Learn More <InfoIcon className="ml-2 h-5 w-5" />
            </button> */}
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg shadow-xl p-6 mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-center text-purple-400">Scan to Donate</h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <div className="bg-white p-4 rounded-lg">
              <img src={QRCode} alt="QR Code" className="w-64 h-64 mx-auto" />
              {/* <QRCode value={donationLink} size={200} /> */}
            </div>
            <div className="text-center md:text-left">
              <p className="text-lg mb-4">Scan this QR code with your smartphone to make a quick and easy donation.</p>
              <p className="text-sm text-gray-400">
                The QR code will direct you to our secure donation page where you can choose your donation amount.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-semibold mb-6 text-center text-purple-400">User Who Donate</h2>
          <div className="grid md:grid-cols-3 gap-6">
            {userDonations.map((donation, index) => (
              <div key={index} className="bg-gray-700 rounded-lg p-4 text-center">
                <UserIcon className="mx-auto h-12 w-12 text-purple-400 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{donation.name}</h3>
                <p className="text-xl font-bold text-purple-400 mb-2">{donation.amount}</p>
                <p className="text-gray-300 italic">"{donation.message}"</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mt-12 text-center mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-purple-400">Join These Amazing Donors</h2>
          <p className="text-gray-300 mb-6">Your contribution, big or small, makes a difference. Donate now and be part of our mission!</p>
          <div className='flex justify-center'>
            {/* <button className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center">
              Donate by <HeartIcon className="ml-2 h-5 w-5" />
            </button> */}
          </div>
        </section>
      </main>
      <FooterNav />
    </div>
  );
}
