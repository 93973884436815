import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import axios from 'axios';
import "../home/NewHome.css";
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand, FaCompress, FaThumbsUp, FaShare, FaSearch, FaCopy, FaDownload, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import RecommendItems from '../NewComp/RecommendItems';
import HeaderNew from '../NewComp/HeaderNew';
import FooterNav from '../NewComp/FooterNav';
import Genrers from '../NewComp/Genres';
import Loading from '../../images/loading.gif'
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import AdComponent from './AdsterraAd';
import AceAdBanner from './aceAdBanner';

export default function AnimePaperPage() {
  const { animeId } = useParams();
  const [anime, setAnime] = useState(null);
  const [currentEpisode, setCurrentEpisode] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [isLiking, setIsLiking] = useState(false);

  useEffect(() => {
    const fetchAnimeData = async () => {
      try {
        const response = await axios.get(`https://watchanimehindi.onrender.com/api/anime/url/${animeId}`);
        setAnime(response.data);
        setCurrentEpisode(1); // Reset episode to 1 when fetching new anime data
      }
       catch (error) {
        console.error('Error fetching anime data:', error);
        // Handle error
        Swal.fire({
          icon: 'warning',
          title: 'Sorry for the inconvenience',
          text: `There no Anime im redirecting you to homepage`,
          confirmButtonText: 'Samajh gaya',
          timer: 5000,
          willClose: () => {
            window.location.href = '/';
          }
        });
      }
    };

    fetchAnimeData();
  }, [animeId]);

  if (!anime) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <img src={Loading} alt="Loading" className="w-[100px]" />
      </div>
    );
  }

  const switchEpisode = (episodeNumber) => {
    setCurrentEpisode(episodeNumber);
    const iframe = document.getElementById('episode-video');
    iframe.src = anime.videoIds[episodeNumber];
  };

  const handleSearch = () => {
    const episodeNumber = parseInt(searchValue);
    if (!isNaN(episodeNumber) && episodeNumber >= 1 && episodeNumber <= anime.totalEpisodes) {
      switchEpisode(episodeNumber);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Episode',
        text: `Please enter a valid episode number between 1 and ${anime.totalEpisodes}`,
        confirmButtonText: 'Samajh gaya',
        timer: 3000
      });
    }
  };

  const copyLink = () => {
    const url = `https://www.watchanimehindi.in/${animeId}`;

    // Modern Clipboard API
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Link Copied!',
            text: 'The link has been copied to your clipboard.',
            timer: 1500,
            showConfirmButton: false
          });
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Copy Failed',
            text: 'Failed to copy the link. Please try again.',
          });
        });
    } else {
      // Fallback for older browsers or environments
      const textarea = document.createElement('textarea');
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        Swal.fire({
          icon: 'success',
          title: 'Link Copied!',
          text: 'The link has been copied to your clipboard.',
          timer: 1500,
          showConfirmButton: false
        });
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Copy Failed',
          text: 'Failed to copy the link. Please try again.',
        });
      }
      document.body.removeChild(textarea);
    }
  };


  // Function to generate download link for current episode
  const generateDownloadLink = (episodeNumber) => {
    const videoId = anime.videoIds[episodeNumber];
    const idRegex = /\/d\/([\w-]+)\//;
    const match = videoId.match(idRegex);

    if (match && match.length > 1) {
      return `https://drive.google.com/uc?export=download&id=${match[1]}`;
    }
    return videoId;
  };

  const goToPreviousEpisode = () => {
    if (currentEpisode > 1) {
      switchEpisode(currentEpisode - 1);
    }
  };

  const goToNextEpisode = () => {
    if (currentEpisode < anime.totalEpisodes) {
      switchEpisode(currentEpisode + 1);
    }
  };


    // Function to handle liking anime
    const likeAnime = async () => {
      const likedAnime = JSON.parse(localStorage.getItem('likedAnime')) || {};
    
      // Check if the user has already liked this anime
      if (likedAnime[anime._id]) {
        Swal.fire({
          icon: 'success',
          title: 'Already Liked',
          text: 'You have already liked this anime.',
          timer: 1000,
          showConfirmButton: false,
        });
        return;
      }
    
      setIsLiking(true);
      try {
        const response = await axios.post(`https://watchanimehindi.onrender.com/api/anime/${anime._id}/like`);
        setAnime({ ...anime, likes: response.data.likes });
    
        // Mark this anime as liked in localStorage
        likedAnime[anime._id] = true;
        localStorage.setItem('likedAnime', JSON.stringify(likedAnime));
      } catch (error) {
        console.error('Error liking anime:', error);
      } finally {
        setIsLiking(false);
      }
    };

  return (
    <>
      <Helmet>
        <title>{anime.title} - AceAnime X WatchAnimeHindi </title>
        <meta name="description" content={anime.description} />
        <meta name="keywords" content={`${anime.title}, anime hindi, watch anime hindi online, ace anime, crunchyroll anime, ${anime.genre}`} />
        <link rel="canonical" href={`https://www.watchanimehindi.in/${animeId}`} />
        <meta property="og:image" content={anime.image} />
        <meta name="twitter:image" content={anime.image} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Animes",
            "name": anime.title,
            "description": anime.description,
            "url": `https://www.watchanimehindi.in/${animeId}`,
            "image": anime.image,
            "genre": anime.genre,
            "numberOfEpisodes": anime.totalEpisodes,
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": anime.rating
            }
          })}
        </script>
      </Helmet>
      <div className="bg-gray-900 text-white font-sans pb-16">
        <HeaderNew />
        <div className="min-h-screen py-0 px-4 sm:px-6 lg:px-8">
          <div className=" flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/4 mx-auto max-w-8xl">
              <div className="bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                <div className="relative ">
                  <iframe
                    id="episode-video"
                    src={anime.videoIds[currentEpisode]}
                    className="w-full aspect-video"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title={`${anime.title} Episode ${currentEpisode}`}
                  ></iframe>
                </div>

                <div className="p-8">
                  <div className="flex items-center justify-between mb-6">
                    <h1 className="text-1xl md:text-4xl font-bold">{anime.title}</h1>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={likeAnime}
                        disabled={isLiking} // Disable while the request is processing
                        className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full flex items-center`}
                      >
                        <FaThumbsUp className="mr-2" /> {anime.likes}
                      </button>
                      <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-full flex items-center">
                        <FaShare className="mr-2" /> {anime.shares}
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-wrap items-center text-sm text-gray-400 mb-6">
                    <span className="mr-4">Episodes: {anime.totalEpisodes}</span>
                    <span className="mr-4">Status: {anime.status}</span>
                    <span className="mr-4">Rating: {anime.rating}</span>
                    <span>Genre: {anime.genre}</span>
                  </div>

                  <p className="text-gray-300 mb-6">{anime.description}</p>

                  <div className="mb-6">
                    <div className="text-sm flex justify-between items-center mb-6">
                      <button onClick={goToPreviousEpisode} disabled={currentEpisode === 1} className="bg-gray-700 hover:bg-gray-600 text-white px-6 py-3 rounded-full flex items-center">
                        <FaArrowLeft className="mr-2" /> Previous Episode
                      </button>
                      <button onClick={goToNextEpisode} disabled={currentEpisode === anime.totalEpisodes} className="bg-gray-700 hover:bg-gray-600 text-white px-6 py-3 rounded-full flex items-center">
                        <FaArrowRight className="mr-2" /> Next Episode
                      </button>
                    </div>

                    {/* Download button for the current episode */}
                    <div className="flex justify-center mb-6">
                      <a
                        href={generateDownloadLink(currentEpisode)}
                        className="bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-full flex items-center"
                        download={`Episode_${currentEpisode}`}
                      >
                        <FaDownload className="mr-2" /> Download Episode {currentEpisode}
                      </a>
                    </div>

                    <h2 className="text-xl font-semibold mb-2 ">Episodes</h2>
                    <div className="flex items-center space-x-2 mb-4">
                      <input
                        type="text"
                        placeholder="Enter episode no."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                        className="bg-gray-700 text-white px-3 py-2 md:w-64 w-40 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <button onClick={handleSearch} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-r-full">
                        <FaSearch />
                      </button>
                    </div>
                    <div className="scrollable-episodes-container">
                      <div className="grid grid-cols-5 sm:grid-cols-8 md:grid-cols-10 gap-2">
                        {Object.keys(anime.videoIds).map((episodeNumber) => (
                          <button
                            key={episodeNumber}
                            className={`px-3 py-2 rounded-full ${currentEpisode === parseInt(episodeNumber) ? 'bg-blue-500 text-white' : 'bg-gray-700 hover:bg-gray-600'}`}
                            onClick={() => switchEpisode(parseInt(episodeNumber))}
                          >
                            {episodeNumber}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>

                  {Object.keys(anime.seasons).length > 0 && (
                    <div className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">Seasons</h2>
                      <div className="flex flex-wrap gap-2">
                        {Object.entries(anime.seasons).map(([seasonNumber, seasonId]) => (
                          <Link key={seasonNumber} to={`/${seasonId}`} className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-full">
                            Season {seasonNumber}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="flex justify-center">
                    <button onClick={copyLink} className="bg-purple-500 hover:bg-purple-600 text-white px-6 py-3 rounded-full flex items-center">
                      <FaCopy className="mr-2" /> Copy Share Link
                    </button>
                  </div>
                  
                </div>
              </div>
              <div className="mt-6">
                <RecommendItems />
              </div>
            </div>

            {/* Right Sidebar for Genres */}
            <aside className="w-full lg:w-1/4 bg-gray-800 shadow-lg rounded-lg p-4 lg:ml-4 lg:mt-0 mt-4">
              <h2 className="text-2xl font-semibold mb-4"> Your Mood Genres</h2>
              <Genrers />
              <h2 className="text-2xl font-semibold mb-4 mt-4 text-center">Ads Section</h2>
              <AceAdBanner />
              <AdComponent />
            </aside>
          </div>
          <FooterNav />
        </div>
      </div>
    </>
  );
}
