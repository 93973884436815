import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css'; // Ensure to import the CSS file


const AdminDashboard = () => {
  const [animeData, setAnimeData] = useState({});
  const [filteredAnimeData, setFilteredAnimeData] = useState({});
  const [selectedAnime, setSelectedAnime] = useState(null);
  const [formData, setFormData] = useState({});
  const [newAnime, setNewAnime] = useState({});
  const [seasonInputs, setSeasonInputs] = useState([]);
  const [videoIdInputs, setVideoIdInputs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [mode, setMode] = useState('add'); // New state variable

  useEffect(() => {
    fetchAnimeData();
  }, []);

  useEffect(() => {
    if (selectedAnime && animeData[selectedAnime]) {
      const anime = animeData[selectedAnime];
      setFormData(anime);
      setSeasonInputs(Object.keys(anime.seasons || {}).map(season => ({
        number: season,
        url: anime.seasons[season]
      })));
      setVideoIdInputs(Object.keys(anime.videoIds || {}).map(episode => ({
        episode: episode,
        id: anime.videoIds[episode]
      })));
    }
  }, [selectedAnime, animeData]);
  

  useEffect(() => {
    const searchLowercased = searchQuery.toLowerCase();
    const filtered = Object.values(animeData).filter(anime =>
      anime.title.toLowerCase().includes(searchLowercased)
    );
    setFilteredAnimeData(filtered.reduce((acc, anime) => ({
      ...acc,
      [anime._id]: anime
    }), {}));
  }, [searchQuery, animeData]);

  const fetchAnimeData = async () => {
    try {
      const response = await axios.get('https://watchanimehindi.onrender.com/api/anime');
      setAnimeData(response.data.reduce((acc, anime) => ({
        ...acc,
        [anime._id]: anime
      }), {}));
    } catch (error) {
      console.error('Error fetching anime data:', error);
    }
  };

  const handleEdit = (id) => {
    setSelectedAnime(id);
    setMode('edit'); // Set mode to 'edit'
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
    if (name === 'totalEpisodes') {
      const totalEpisodes = Number(value) || 0;
      setVideoIdInputs(Array.from({ length: totalEpisodes }, (_, index) => ({
        episode: index + 1,
        id: formData.videoIds?.[index + 1] || ''
      })));
    }
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    setNewAnime(prevData => ({ ...prevData, [name]: value }));
    if (name === 'totalEpisodes') {
      const totalEpisodes = Number(value) || 0;
      setVideoIdInputs(Array.from({ length: totalEpisodes }, (_, index) => ({
        episode: index + 1,
        id: newAnime.videoIds?.[index + 1] || ''
      })));
    }
  };

  const handleSeasonChange = (index, e) => {
    const { name, value } = e.target;
    const newSeasonInputs = [...seasonInputs];
    newSeasonInputs[index] = { ...newSeasonInputs[index], [name]: value };
    setSeasonInputs(newSeasonInputs);
  };

  const handleAddSeason = () => {
    setSeasonInputs([...seasonInputs, { number: '', url: '' }]);
  };

  const handleRemoveSeason = (index) => {
    setSeasonInputs(seasonInputs.filter((_, i) => i !== index));
  };

  const handleVideoIdChange = (episodeNumber, newValue) => {
    setVideoIdInputs(prevInputs => prevInputs.map(input =>
      input.episode === episodeNumber ? { ...input, id: newValue } : input
    ));
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`https://watchanimehindi.onrender.com/api/anime/${selectedAnime}`, {
        ...formData,
        seasons: seasonInputs.reduce((acc, curr) => ({
          ...acc,
          [curr.number]: curr.url
        }), {}),
        videoIds: videoIdInputs.reduce((acc, curr) => ({
          ...acc,
          [curr.episode]: curr.id
        }), {})
      });
      fetchAnimeData(); // Refresh the data
      setSelectedAnime(null);
      setFormData({});
      setSeasonInputs([]);
      setVideoIdInputs([]);
      setMode('add'); // Reset mode to 'add'
    } catch (error) {
      console.error('Error updating anime:', error.response ? error.response.data : error.message);
    }
  };

  const handleNewSubmit = async () => {
    try {
      await axios.post('https://watchanimehindi.onrender.com/api/anime', {
        ...newAnime,
        seasons: seasonInputs.reduce((acc, curr) => ({
          ...acc,
          [curr.number]: curr.url
        }), {}),
        videoIds: videoIdInputs.reduce((acc, curr) => ({
          ...acc,
          [curr.episode]: curr.id
        }), {})
      });
      fetchAnimeData(); // Refresh the data
      setNewAnime({});
      setSeasonInputs([]);
      setVideoIdInputs([]);
    } catch (error) {
      console.error('Error adding new anime:', error.response ? error.response.data : error.message);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this anime?');

    if (confirmed) {
      try {
        await axios.delete(`https://watchanimehindi.onrender.com/api/anime/${id}`);
        fetchAnimeData(); // Refresh the data
      } catch (error) {
        console.error('Error deleting anime:', error.response ? error.response.data : error.message);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="admin-dashboard">
      <h1 className="dashboard-title">Admin Dashboard</h1>

      {/* Search Section */}
      <div className="search-section-admin">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input-admin"
          placeholder="Search by title"
        />
      </div>

      {/* Add New Anime Section */}
      {mode === 'add' && (
        <div className="form-section-admin">
          <h2 className="form-title">Add New Anime</h2>
          <div className="form-container-admin">
            <input
              name="title"
              value={newAnime.title || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Title"
            />
            <input
              name="image"
              value={newAnime.image || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Image URL"
            />
            <textarea
              name="description"
              value={newAnime.description || ''}
              onChange={handleNewChange}
              className="form-textarea"
              placeholder="Description"
            />
            <input
              name="totalEpisodes"
              type="number"
              value={newAnime.totalEpisodes || ''}
              onChange={e => {
                handleNewChange(e);
                const totalEpisodes = Number(e.target.value) || 0;
                setVideoIdInputs(Array.from({ length: totalEpisodes }, (_, index) => ({
                  episode: index + 1,
                  id: newAnime.videoIds?.[index + 1] || ''
                })));
              }}
              className="form-input"
              placeholder="Total Episodes"
            />
            {videoIdInputs.map((input, index) => (
              <textarea
                key={index}
                value={input.id || ''}
                onChange={(e) => handleVideoIdChange(input.episode, e.target.value)}
                className="form-textarea"
                placeholder={`Video ID for Episode ${input.episode}`}
              />
            ))}
            <input
              name="status"
              value={newAnime.status || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Status"
            />
            <input
              name="rating"
              value={newAnime.rating || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Rating"
            />
            <input
              name="genre"
              value={newAnime.genre || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Genre"
            />
            <input
              name="animeUrl"
              value={newAnime.animeUrl || ''}
              onChange={handleNewChange}
              className="form-input"
              placeholder="Anime URL"
            />
            <input
              name="date"
              type="date"
              value={newAnime.date || ''}
              onChange={handleNewChange}
              className="form-input"
            />
            {/* Dynamic season inputs */}
            {seasonInputs.map((season, index) => (
              <div key={index} className="season-input">
                <input
                  name="number"
                  value={season.number || ''}
                  onChange={(e) => handleSeasonChange(index, e)}
                  className="form-input"
                  placeholder="Season Number"
                />
                <input
                  name="url"
                  value={season.url || ''}
                  onChange={(e) => handleSeasonChange(index, e)}
                  className="form-input"
                  placeholder="Season URL"
                />
                <button type="button" onClick={() => handleRemoveSeason(index)} className="button-admin remove-season-button">Remove Season</button>
              </div>
            ))}
            <button type="button" onClick={handleAddSeason} className="button-admin add-season-button">Add Season</button>
            <button className="button-admin form-submit-button" onClick={handleNewSubmit}>Add Anime</button>
          </div>
        </div>
      )}

      {/* Edit Existing Anime Section */}
      {mode === 'edit' && selectedAnime && (
        <div className="form-section-admin">
          <h2 className="form-title">Edit Anime</h2>
          <div className="form-container-admin">
            <input
              name="title"
              value={formData.title || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Title"
            />
            <input
              name="image"
              value={formData.image || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Image URL"
            />
            <textarea
              name="description"
              value={formData.description || ''}
              onChange={handleChange}
              className="form-textarea"
              placeholder="Description"
            />
            <input
              name="totalEpisodes"
              type="number"
              value={formData.totalEpisodes || ''}
              onChange={e => {
                handleChange(e);
                const totalEpisodes = Number(e.target.value) || 0;
                setVideoIdInputs(Array.from({ length: totalEpisodes }, (_, index) => ({
                  episode: index + 1,
                  id: formData.videoIds?.[index + 1] || ''
                })));
              }}
              className="form-input"
              placeholder="Total Episodes"
            />
            {videoIdInputs.map((input, index) => (
              <textarea
                key={index}
                value={input.id || ''}
                onChange={(e) => handleVideoIdChange(input.episode, e.target.value)}
                className="form-textarea"
                placeholder={`Video ID for Episode ${input.episode}`}
              />
            ))}
            <input
              name="status"
              value={formData.status || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Status"
            />
            <input
              name="rating"
              value={formData.rating || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Rating"
            />
            <input
              name="genre"
              value={formData.genre || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Genre"
            />
            <input
              name="animeUrl"
              value={formData.animeUrl || ''}
              onChange={handleChange}
              className="form-input"
              placeholder="Anime URL"
            />
            <input
              name="date"
              type="date"
              value={formData.date ? formData.date.split('T')[0] : ''} // Formatting date for input
              onChange={handleChange}
              className="form-input"
            />
            {/* Dynamic season inputs */}
            {seasonInputs.map((season, index) => (
              <div key={index} className="season-input">
                <input
                  name="number"
                  value={season.number || ''}
                  onChange={(e) => handleSeasonChange(index, e)}
                  className="form-input"
                  placeholder="Season Number"
                />
                <input
                  name="url"
                  value={season.url || ''}
                  onChange={(e) => handleSeasonChange(index, e)}
                  className="form-input"
                  placeholder="Season URL"
                />
                <button type="button" onClick={() => handleRemoveSeason(index)} className="button-admin remove-season-button">Remove Season</button>
              </div>
            ))}
            <button type="button" onClick={handleAddSeason} className="button-admin add-season-button">Add Season</button>
            <button className="button-admin form-submit-button" onClick={handleSubmit}>Save Changes</button>
          </div>
        </div>
      )}

      {/* Display List of Anime */}
      <div className="anime-list">
        <h2 className="list-title">Anime List</h2>
        <ul className="anime-list-items">
          {Object.values(filteredAnimeData).map(anime => (
            <li key={anime._id} className="anime-list-item">
              <img src={anime.image} alt={anime.title} className="rezani-image" />
              <span className="anime-title">{anime.title}</span>
              <div className='divider-button'>
                <button type="button" onClick={() => handleEdit(anime._id)} className="button-admin edit-button">Edit</button>
                <button type="button" onClick={() => handleDelete(anime._id)} className="button-admin button-danger delete-button">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;
