import { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';
import { useAnime } from '../NewComp/AnimeContext';

const genres = [
  "Action", "Adventure", "Comedy", "Drama", "Fantasy", "Horror", "Mystery", "Romance", "Sci-Fi", "Slice of Life", "Sport", "Thriller", "War", "shonen", "Sword & Sorcery", "Fairy", "Iyashikei", "Psychological Drama"
];

const upcomingReleases = [
  { title: "Chainsaw Man: Season 2", date: "Fall 2023" },
  { title: "Jujutsu Kaisen: Season 2", date: "July 2023" },
  { title: "One Punch Man: Season 3", date: "TBA 2023" },
];

export default function NewHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGenre, setSelectedGenre] = useState(null);

  const { animeData, loading } = useAnime();

  const sectionRefs = {
    latest: useRef(null),
    recommended: useRef(null),
    movies: useRef(null)
  };


  const filterAnime = (animeList) => {
    return animeList.filter(anime =>
      anime.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      anime.alt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Map episode count from anime data to anime lists
  const addEpisodesToAnime = (animeList) => {
    return animeList.map(anime => {
      const animeDetail = animeData.animeDetails.find(a => a.animeUrl === anime.link);
      return {
        ...anime,
        genre: animeDetail ? animeDetail.genre : 'Unknown',
        totalEpisodes: animeDetail ? animeDetail.totalEpisodes : 'Unknown'
      };
    });
  };

  const searchResultsWithEpisodes = useMemo(() => addEpisodesToAnime(filterAnime(animeData.allAnime || [])), [animeData.allAnime, searchQuery]);

  const handleScroll = (section, direction) => {
    const ref = sectionRefs[section].current;
    if (ref) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      ref.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const filterByGenre = (animeList, genre) => {
    if (!genre) return animeList; // Return all if no genre is selected
    return animeList.filter(anime => anime.genre?.toLowerCase().includes(genre.toLowerCase()));
  };

  // Function to handle genre click
  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
  };

  const renderAnimeGrid = (animeList, section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {animeList.map((anime) => (
          <div key={anime._id} className="min-w-[210px] rounded-lg overflow-hidden shadow-md">
            <Link key={anime.link} to={`/${anime.link}`} >
              <img
                src={anime.src}
                alt={anime.alt}
                className="w-full h-[270px] object-cover"
                />
                {anime.totalEpisodes !== undefined && (
                  <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
                )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-bold">{anime.title}</h3>
                {/* Display total episodes */}
                
              </div>
            </Link>
          </div>
        ))}
      </div>
      
    </div>
  );

  return (
    <>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Genres</h2>
        <div className="flex flex-wrap gap-2">
          {genres.map((genre) => (
            <button
              key={genre}
              onClick={() => handleGenreClick(genre)}
              className={`px-4 py-2 rounded-full text-sm border ${
                selectedGenre === genre
                  ? "bg-pink-500 text-white"
                  : "bg-transparent text-gray-400 border-gray-400 hover:bg-pink-500 hover:text-white"
              }`}
            >
              {genre}
            </button>
          ))}
        </div>
      </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            {selectedGenre ? `Anime in ${selectedGenre}` : 'All Anime'}
          </h2>
          {loading ? <p>Loading...</p> : renderAnimeGrid(filterByGenre(searchResultsWithEpisodes, selectedGenre), 'search')}
        </section>

        
    </>
  )


}