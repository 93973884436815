import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './compounds/home/home';
import AnimeDetail from './compounds/utils/AnimeDetail';
import AdminDashboard from './compounds/utils/AdminDashboard';
import ItemsAdmin from './compounds/utils/ItemsAdmin';
import LoginPage from './compounds/utils/LoginPage';
import { AuthProvider } from './compounds/utils/AuthContext';
import ProtectedRoute from './compounds/utils/ProtectedRoute';
import Explore from './compounds/NewComp/Explore';
import DonationPage from './compounds/NewComp/Donation';
import DarkPrivacyPolicyPage from './compounds/NewComp/PrivacyPolicy';
import "./App.css";
import NewLat from './compounds/NewComp/NewLat';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/:animeId" element={<AnimeDetail />} />
      <Route path="/loginAdmin-053" element={<LoginPage />} />
      <Route
        path="/adminzratghost053"
        element={<ProtectedRoute element={<AdminDashboard />} />}
      />
      <Route
        path="/adminzratghost053/items"
        element={<ProtectedRoute element={<ItemsAdmin />} />}
      />
      <Route path="/newlat" element={<NewLat />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/donate" element={<DonationPage />} />
      <Route path="/policy" element={<DarkPrivacyPolicyPage />} />
      
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
