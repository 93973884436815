import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import '../home/NewHome.css';

export default function NewHome() {
  const [latestAnime, setLatestAnime] = useState([]);
  const [loading, setLoading] = useState(true);
  const sectionRefs = useRef(null);

  useEffect(() => {
    // Fetch the latest anime list when component mounts
    fetch('https://watchanimehindi.onrender.com/api/latest-anime')
      .then((response) => response.json())
      .then((data) => {
        setLatestAnime(data);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching latest anime:', error));
  }, []);

  const renderAnimeGrid = (animeList) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs}>
        {animeList.map((anime) => (
          <div key={anime._id} className="min-w-[210px] rounded-lg overflow-hidden shadow-md">
            <Link to={`/${anime.animeUrl}`}>
              <img
                src={anime.image}
                alt={anime.title}
                className="w-full h-[270px] object-cover"
              />
              {anime.totalEpisodes !== undefined && (
                <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
              )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-bold">{anime.title}</h3>
                <p className="text-xs text-gray-400">Updated: {new Date(anime.updatedAt).toLocaleString()}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="bg-black">
      <h2 className="text-lg font-bold mb-2">Latest Anime</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        renderAnimeGrid(latestAnime)
      )}
    </div>
  );
}
