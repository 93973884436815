import { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';
import Loading from "../../images/loading.gif"
import { useAnime } from './AnimeContext';

export default function NewHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGenre, setSelectedGenre] = useState(null);

  const { animeData, loading } = useAnime();
  const sectionRefs = {
    latest: useRef(null),
    recommended: useRef(null),
    movies: useRef(null)
  };


  const filterAnime = (animeList) => {
    return animeList.filter(anime =>
      anime.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      anime.alt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };


  // Map episode count from anime data to anime lists
  const addEpisodesToAnime = (animeList) => {
    return animeList.map(anime => {
      const animeDetail = animeData.animeDetails.find(a => a.animeUrl === anime.link);
      return {
        ...anime,
        genre: animeDetail ? animeDetail.genre : 'Unknown',
        totalEpisodes: animeDetail ? animeDetail.totalEpisodes : 'Unknown'
      };
    });
  };

  const searchResultsWithEpisodes = useMemo(() => addEpisodesToAnime(filterAnime(animeData.allAnime || [])).reverse(), [animeData.allAnime, searchQuery]);

  const allanimeNo = searchResultsWithEpisodes.length;

  const handleScroll = (section, direction) => {
    const ref = sectionRefs[section].current;
    if (ref) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      ref.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const filterByGenre = (animeList, genre) => {
    if (!genre) return animeList; // Return all if no genre is selected
    return animeList.filter(anime => anime.genre?.toLowerCase().includes(genre.toLowerCase()));
  };


  const renderAnimeGrid = (animeList, section) => (
    <div className="relative">
      <div className=" flex flex-wrap justify-center md:gap-4 gap-2 " ref={sectionRefs[section]}>
        {animeList.map((anime) => (
          <div key={anime._id} className="w-[170px] rounded-lg overflow-hidden shadow-md md:w-[210px]">
            <Link key={anime.link} to={`/${anime.link}`} >
              <img
                src={anime.src}
                alt={anime.alt}
                className="w-[170px] md:h-[270px] object-cover md:w-[210px] h-[210px]"
              />
              {anime.totalEpisodes !== undefined && (
                <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
              )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-semibold">{anime.title}</h3>
                {/* Display total episodes */}

              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>

  );

  return (

    <section className="mb-8">
      <div className='flex justify-between'>
        <h2 className="text-xl font-semibold mb-4">Explore Anime</h2>
        <p className='text-xs p-2'>{allanimeNo} Animes</p>
      </div>
      <div className='mb-4 flex justify-center gap-2 flex-wrap'>
        <iframe data-aa='2355737' src='//ad.a-ads.com/2355737?size=300x250' style={window.innerWidth < 768 ? { width: '300px', height: '250px' } : { width: '300px', height: '250px' }} frameBorder='0' ></iframe>
      </div>

      {loading ? <div className='flex justify-center items-center h-[300px]'><img src={Loading} alt="Loading..." className='w-20' /></div> : renderAnimeGrid(searchResultsWithEpisodes, 'allAnime')}
    </section>

  )


}