import React, { useEffect } from 'react';

const MediumBannerAd = () => {
  useEffect(() => {
    // Define the atOptions object for this ad
    window.atOptions = {
      'key': 'ed255abee1814c6913419fcbdf5a581d',
      'format': 'iframe',
      'height': 250,
      'width': 300,
      'params': {}
    };

    // Create the script element for the ad
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//www.topcreativeformat.com/ed255abee1814c6913419fcbdf5a581d/invoke.js';

    // Append the script to the ad container
    const adContainer = document.getElementById('medium-banner-ad');
    adContainer.appendChild(script);

    // Cleanup function to remove the script on component unmount
    return () => {
      adContainer.removeChild(script);
    };
  }, []);

  return (
    <div className='flex justify-center'>
      {/* Container for the medium banner ad */}
      <div id="medium-banner-ad" style={{ width: '300px', height: '250px' }}></div>
    </div>
  );
};

export default MediumBannerAd;
