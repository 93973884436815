import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('data-cfasync', 'false');
    script.src = '//pl24498156.cpmrevenuegate.com/e8ffc74c8a5b1894670ec8543a20771b/invoke.js';

    // Append script to the ad container
    const adContainer = document.getElementById('container-e8ffc74c8a5b1894670ec8543a20771b');
    if (adContainer) {
      adContainer.appendChild(script);
    }

    // Cleanup function to remove the script safely on component unmount
    return () => {
      if (adContainer && adContainer.contains(script)) {
        adContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      {/* The container where the ad will be injected */}
      <div id="container-e8ffc74c8a5b1894670ec8543a20771b"></div>
    </div>
  );
};

export default AdComponent;
