import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';
import HeaderNew from '../NewComp/HeaderNew';
import FooterNav from '../NewComp/FooterNav';
import AllAnime from '../NewComp/AllAnime';

export default function NewHome() {


  return (
    <div className="bg-gray-900 text-white font-sans pb-16">
      <HeaderNew/>

      <main className="px-4">
        <AllAnime />
      </main>

      <FooterNav />
    </div>
  );
}
