import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User, ListCollapse } from 'lucide-react';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';

export default function Nav() {
  return(
    <nav className="fixed bottom-0 left-0 right-0 bg-gray-800 p-2 flex justify-around items-center md:hidden z-10">
        <Link to="/" className="text-gray-400 hover:text-white flex flex-col items-center">
          <Home className="w-6 h-6" />
          <span className="text-xs">Home</span>
        </Link>
        <Link to="/explore" className="text-gray-400 hover:text-white flex flex-col items-center">
          <Compass className="w-6 h-6" />
          <span className="text-xs">Explore</span>
        </Link>
        <Link to="/donate" className="text-gray-400 hover:text-white flex flex-col items-center">
          <Heart className="w-6 h-6" />
          <span className="text-xs">Donate</span>
        </Link>
        <Link to="/policy" className="text-gray-400 hover:text-white flex flex-col items-center">
          <ListCollapse className="w-6 h-6" />
          <span className="text-xs">Policy</span>
        </Link>
      </nav>
  )
}