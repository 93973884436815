import { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Heart } from 'lucide-react';
import './NewHome.css';
import { Link } from 'react-router-dom';
import Spotlight from '../NewComp/Spotlight';
import RecommendItems from '../NewComp/RecommendItems';
import HeaderNew from '../NewComp/HeaderNew';
import FooterNav from '../NewComp/FooterNav';
import Genres from '../NewComp/Genres';
import { useAnime } from '../NewComp/AnimeContext';
import Latest from '../NewComp/Latest';
import Notify from '../utils/notify';
import AdComponent from '../utils/AdsterraAd';
import axios from 'axios';
import AceBannerAd from '../utils/aceAdBanner';
import GoalBar from '../NewComp/GoalBar';



const genres = [
  "Action", "Adventure", "Comedy", "Drama", "Fantasy", "Horror", "Mystery", "Romance", "Sci-Fi", "Slice of Life", "Sport", "Thriller"
];

const upcomingReleases = [
  { title: "Chainsaw Man: Season 2", date: "Isi Jindagi me" },
  { title: "Jujutsu Kaisen: Season 3", date: "Me Khud Wait kar Raha ji" },
  { title: "One Punch Man: Season 3", date: "Notify abhi kaam nhi karega" },
];

const donationGoal = 10000; // Goal amount
const totalDonations = 56; // Current donation amount

const loadingbox = new Array(9).fill({ img: "1" });

export default function NewHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0)
  const touchStartX = useRef(0);

  // const [userIp, setUserIp] = useState('');
  // const [isGhost, setIsGhost] = useState(false); 

  const { animeData, loading } = useAnime();
  const sectionRefs = {
    latest: useRef(null),
    recommended: useRef(null),
    movies: useRef(null),
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX; // Get the X position where the touch started
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX; // Get the X position where the touch ended
    if (touchStartX.current - touchEndX > 50) {
      // Swipe left, go to next slide
      setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : 1));
    } else if (touchStartX.current - touchEndX < -50) {
      // Swipe right, go to previous slide
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }
  };

  const handleMouseDown = (e) => {
    touchStartX.current = e.clientX;
  };

  const handleMouseUp = (e) => {
    const touchEndX = e.clientX;
    if (touchStartX.current - touchEndX > 50) {
      setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : 1));
    } else if (touchStartX.current - touchEndX < -50) {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index); // Change to the selected slide when the button is clicked
  };

  // Fetch user's IP address
  // useEffect(() => {
  //   const fetchUserIp = async () => {
  //     try {
  //       const response = await axios.get('https://api.ipify.org?format=json');
  //       setUserIp(response.data.ip);

  //       // Check if the user's IP matches Ghost's IP
  //       if (response.data.ip === '152.58.177.126') {
  //         setIsGhost(true);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching IP address:', error);
  //     }
  //   };

  //   fetchUserIp();
  // }, []);

  const filterAnime = (animeList) => {
    return animeList.filter(anime =>
      anime.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      anime.alt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const addEpisodesToAnime = (animeList) => {
    return animeList.map(anime => {
      const animeDetail = animeData.animeDetails.find(a => a.animeUrl === anime.link);
      return {
        ...anime,
        genre: animeDetail ? animeDetail.genre : 'Unknown',
        totalEpisodes: animeDetail ? animeDetail.totalEpisodes : 'Unknown'
      };
    });
  };

  const moviesWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.movies || []).reverse(), [animeData.movies]);
  const latestWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.latest || []).reverse(), [animeData.latest]);
  const recommendedWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.recommended || []), [animeData.recommended]);
  const searchResultsWithEpisodes = useMemo(() => addEpisodesToAnime(filterAnime(animeData.allAnime || [])), [animeData.allAnime, searchQuery]);

  const handleScroll = (section, direction) => {
    const ref = sectionRefs[section].current;
    if (ref) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      ref.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const filterByGenre = (animeList, genre) => {
    if (!genre) return animeList;
    return animeList.filter(anime => anime.genre?.toLowerCase().includes(genre.toLowerCase()));
  };

  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
  };

  const Loading = (loadingbox, section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {loadingbox.map((_, index) => (
          <div key={index} className="min-w-[210px] rounded-lg overflow-hidden shadow-md animate-pulse">
            <div className="w-full h-[270px] bg-gray-700"></div>
            <div className="p-4 bg-gray-800 h-[65px]">
              <div className="bg-gray-600 h-4 w-3/4 mb-2"></div>
              <div className="bg-gray-600 h-4 w-1/2"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderAnimeGrid = (animeList, section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {animeList.map((anime) => (
          <div key={anime._id} className="min-w-[210px] rounded-lg overflow-hidden shadow-md">
            <Link key={anime.link} to={`/${anime.link}`}>
              <img
                src={anime.src}
                alt={anime.alt}
                className="w-full h-[270px] object-cover"
              />
              {anime.totalEpisodes !== undefined && (
                <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
              )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-bold">{anime.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={() => handleScroll(section, 'left')}
        aria-label={`Scroll left in ${section}`}
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={() => handleScroll(section, 'right')}
        aria-label={`Scroll right in ${section}`}
      >
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  );

  return (
    <div className="bg-gray-900 text-white font-sans pb-16">
      <HeaderNew />

      <main className="px-4">
        {/* Welcome message for Zatrix */}
        {/* {isGhost && (
          <div className="bg-green-500 text-white p-4 rounded-lg text-center mb-4">
            Bhai Zatrix Kya kar rahe ho Aha So jao
          </div>
        )} */}
        <div
          className=" bg-gray-900 text-gray-100"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          >
          {/* Slide 2: Notify Component */}
          {currentIndex === 0 && (
            <div className="p-8">
              <Notify />
            </div>
          )}
          {/* Slide 1: GoalBar Component */}
          {currentIndex === 1 && (
            <div className="p-8">
              <GoalBar totalDonations={totalDonations} donationGoal={donationGoal} />
              <div className='flex justify-center'>
              <Link to="/donate" className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center text-1xl"> Donate <span className="ml-2 h-5 w-5" /><Heart/> </Link>
              </div>
            </div>
          )}


          {/* Indicator Buttons */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={() => handleIndicatorClick(0)}
              className={`w-4 h-4 rounded-full ${currentIndex === 0 ? 'bg-purple-500' : 'bg-gray-500'}`}
            ></button>
            <button
              onClick={() => handleIndicatorClick(1)}
              className={`w-4 h-4 rounded-full ${currentIndex === 1 ? 'bg-purple-500' : 'bg-gray-500'}`}
            ></button>
          </div>
        </div>
        <Spotlight />
        <Latest />


        <RecommendItems />
        <div className='mb-4 flex justify-center gap-2 flex-wrap'>
          <iframe data-aa='2355737' src='//ad.a-ads.com/2355737?size=300x250' style={window.innerWidth < 768 ? { width: '300px', height: '250px' } : { width: '300px', height: '250px' }} frameBorder='0' ></iframe>
        </div>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Movies</h2>
          {loading ? Loading(loadingbox, 'movies') : renderAnimeGrid(moviesWithEpisodes, 'movies')}
        </section>

        <Genres onGenreClick={handleGenreClick} />

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Upcoming Releases</h2>
          <div className="space-y-4">
            {upcomingReleases.map((release, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded-lg flex justify-between items-center">
                <div>
                  <h3 className="font-bold">{release.title}</h3>
                  <p className="text-sm text-gray-400">{release.date}</p>
                </div>
                <button className="bg-pink-500 text-white px-3 py-1 rounded-full text-sm">
                  Notify Me
                </button>
              </div>
            ))}
          </div>
        </section>

        <AdComponent />
      </main>

      <FooterNav />
    </div>
  );
}
