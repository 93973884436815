import { useState, useMemo, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import './ShimmerEffect.css'; // Ensure this CSS file contains the shimmer effect styles
import { Link } from 'react-router-dom';
import { useAnime } from './AnimeContext';

const loadingbox = new Array(9).fill({});

export default function NewHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGenre, setSelectedGenre] = useState(null);

  const { animeData, loading } = useAnime();
  const sectionRefs = {
    latest: useRef(null),
    recommended: useRef(null),
    movies: useRef(null)
  };

  const filterAnime = (animeList) => {
    return animeList.filter(anime =>
      anime.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      anime.alt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const addEpisodesToAnime = (animeList) => {
    return animeList.map(anime => {
      const animeDetail = animeData.animeDetails.find(a => a.animeUrl === anime.link);
      return {
        ...anime,
        genre: animeDetail ? animeDetail.genre : 'Unknown',
        totalEpisodes: animeDetail ? animeDetail.totalEpisodes : 'Unknown'
      };
    });
  };

  const moviesWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.movies || []), [animeData.movies]);
  const latestWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.latest || []).reverse(), [animeData.latest]);
  const recommendedWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.recommended || []), [animeData.recommended]);
  const searchResultsWithEpisodes = useMemo(() => addEpisodesToAnime(filterAnime(animeData.allAnime || [])), [animeData.allAnime, searchQuery]);

  const handleScroll = (section, direction) => {
    const ref = sectionRefs[section].current;
    if (ref) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      ref.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const filterByGenre = (animeList, genre) => {
    if (!genre) return animeList;
    return animeList.filter(anime => anime.genre?.toLowerCase().includes(genre.toLowerCase()));
  };

  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
  };

  const Loading = (section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {loadingbox.map((_, index) => (
          <div key={index} className="min-w-[210px] rounded-lg overflow-hidden shadow-md animate-pulse">
            <div className="w-full h-[270px] bg-gray-700"></div>
            <div className="p-4 bg-gray-800 h-[65px]">
              <div className="bg-gray-600 h-4 w-3/4 mb-2"></div>
              <div className="bg-gray-600 h-4 w-1/2"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderAnimeGrid = (animeList, section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {animeList.map((anime) => (
          <div key={anime._id} className="min-w-[210px] rounded-lg overflow-hidden shadow-md">
            <Link key={anime.link} to={`/${anime.link}`}>
              <img
                src={anime.src}
                alt={anime.alt}
                className="w-full h-[270px] object-cover"
              />
              {anime.totalEpisodes !== undefined && (
                <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
              )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-bold">{anime.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={() => handleScroll(section, 'left')}
        aria-label={`Scroll left in ${section}`}
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={() => handleScroll(section, 'right')}
        aria-label={`Scroll right in ${section}`}
      >
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  );

  return (
    <section className="mb-8">
      <h2 className="text-xl font-semibold mb-4">Recommended Anime</h2>
      {loading ? Loading('recommended') : renderAnimeGrid(recommendedWithEpisodes, 'recommended')}
    </section>
  );
}
