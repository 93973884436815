import React from 'react';

export default function GoalBar({ totalDonations, donationGoal }) {
  const percentage = (totalDonations / donationGoal) * 100;

  return (
    <section className="text-center mb-12">
      <h1 className="text-4xl md:text-3xl font-bold mb-4 text-purple-400">Donation Goal</h1>
      <div className="relative h-8 w-full max-w-lg mx-auto bg-gray-800 rounded-full overflow-hidden">
        <div
          className="h-full bg-purple-500"
          style={{ width: `${percentage}%` }}
        ></div>
        <p className="absolute inset-0 flex justify-center items-center text-white font-semibold">
          ₹{totalDonations}/{donationGoal} (₹) Donated
        </p>
      </div>
      <p className="text-lg text-gray-400 mt-4">
        This <spam className="text-purple-400 font-bold">WatchAnimeHindi</spam> is Totally free & Ads Free.<br></br> Make Donation to Continue this Site.
      </p>
    </section>
  );
}
