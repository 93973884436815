import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';

const spotlightItems = [
  {
    id: 1,
    link: "/frieren-beyond-journey-s-end",
    title: "Frieren: Beyond the journey's end",
    genre: "Action, Dark Fantasy",
    image: "https://th-i.thgim.com/public/entertainment/movies/o2d0c8/article68031259.ece/alternates/FREE_1200/frieren-sousou-no-frieren-anime-hd-wallpaper-uhdpaper.com-6021n.jpg"
  },
  {
    id: 2,
    link: "/vinland-saga",
    title: "Vinland Saga",
    genre: "Warrior, Action, Historical",
    image: "https://staticg.sportskeeda.com/editor/2023/01/edd25-16737314765060-1920.jpg"
  },
  {
    id: 3,
    link: "/demon-slayer-s4",
    title: "Demon Slayer: Mugen Train Arc",
    genre: "Adventure, Supernatural",
    image: "https://i0.wp.com/www.smartprix.com/bytes/wp-content/uploads/2023/05/Deon-Slayer.jpg?ssl=1&quality=80&w=f"
  }
];



export default function Spotlight() {
  const [currentSpotlight, setCurrentSpotlight] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSpotlight((prev) => (prev + 1) % spotlightItems.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const nextSpotlight = () => {
    setCurrentSpotlight((prev) => (prev + 1) % spotlightItems.length);
  };

  const prevSpotlight = () => {
    setCurrentSpotlight((prev) => (prev - 1 + spotlightItems.length) % spotlightItems.length);
  };

  return (
    <section className="mb-8 relative">
          <h2 className="text-xl font-semibold mb-4">Spotlight</h2>
          <div className="relative overflow-hidden rounded-lg shadow-lg" style={{ height: '65vh' }}>
            {spotlightItems.map((item, index) => (
              <div
                key={item.id}
                className={`absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out ${index === currentSpotlight ? 'translate-x-0' : 'translate-x-full'}`}
                style={{ transform: `translateX(${(index - currentSpotlight) * 100}%)` }}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                  <h3 className="text-lg font-bold">{item.title}</h3>
                  <p className="text-sm text-gray-300">{item.genre}</p>
                  <div className="mt-2 flex space-x-2">
                    <Link key={item.link} to={`${item.link}`}  className="bg-pink-500 text-white px-4 py-2 rounded-full flex items-center">
                      <Play className="w-4 h-4 mr-2" /> Watch Now
                    </Link>
                    {/* <button className="bg-gray-700 text-white px-4 py-2 rounded-full flex items-center">
                      <Info className="w-4 h-4 mr-2" /> Details
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={prevSpotlight}
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            aria-label="Previous spotlight"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={nextSpotlight}
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            aria-label="Next spotlight"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {spotlightItems.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSpotlight(index)}
                className={`w-2 h-2 rounded-full ${index === currentSpotlight ? 'bg-white' : 'bg-gray-500'}`}
                aria-label={`Go to spotlight ${index + 1}`}
              />
            ))}
          </div>
        </section>
  )



}