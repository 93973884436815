import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AnimeContext = createContext();

export const AnimeProvider = ({ children }) => {
  const [animeData, setAnimeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [allAnimeResponse, moviesResponse, latestResponse, recommendedResponse, animeResponse] = await Promise.all([
          axios.get('https://watchanimehindi.onrender.com/api/rezani'),
          axios.get('https://watchanimehindi.onrender.com/api/movies-rezani'),
          axios.get('https://watchanimehindi.onrender.com/api/latest-rezani'),
          axios.get('https://watchanimehindi.onrender.com/api/recommended-rezani'),
          axios.get('https://watchanimehindi.onrender.com/api/anime')
        ]);
        setAnimeData({
          allAnime: allAnimeResponse.data,
          movies: moviesResponse.data,
          latest: latestResponse.data,
          recommended: recommendedResponse.data,
          animeDetails: animeResponse.data
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <AnimeContext.Provider value={{ animeData, loading, error }}>
      {children}
    </AnimeContext.Provider>
  );
};

export const useAnime = () => useContext(AnimeContext);
