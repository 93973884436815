import React, { useState } from 'react';
import { Shield, Lock, Eye, Trash, ArrowRight, Mail, Siren, MailX, X , Server, TriangleAlert, CircleOff, Link2 } from 'lucide-react';
import "../home/NewHome.css";
import HeaderNew from '../NewComp/HeaderNew';
import FooterNav from '../NewComp/FooterNav';
import { Link } from 'react-router-dom';



const MinimalistPrivacyPolicyPage = () => {
  const [activeSection, setActiveSection] = useState(null);

  const policyItems = [
    {
      title: 'Content Hosting and Distribution',
      icon: <Server className="w-6 h-6" />,
      content: 'Our platform does not host, store, or distribute any multimedia content directly. Instead, we provide links to third-party websites where the content is hosted. We do not control or have any influence over these external websites. The responsibility for hosting and maintaining the content lies solely with the third-party providers.'
    },
    {
      title: 'Third-Party Websites',
      icon: <Link2 className="w-6 h-6" />,
      content: 'The links provided on our platform lead to content hosted on third-party websites. We do not endorse, verify, or guarantee the accuracy, legality, or quality of the content found on these external sites. The content and any associated claims are the responsibility of the third-party websites, not our platform.'
    },
    {
      title: 'Content Accuracy and Legality',
      icon: <TriangleAlert className="w-6 h-6" />,
      content: 'We strive to provide links to content that is publicly accessible and legally available. However, we cannot guarantee the accuracy, completeness, or legality of the content on third-party websites. Users are encouraged to verify the content directly with the source and exercise caution when accessing external links.'
    },
    {
      title: 'Commitment Against Piracy',
      icon: <CircleOff className="w-6 h-6" />,
      content: 'We are dedicated to combating piracy and ensuring that the content linked through our platform is legally distributed. We only link to content that is publicly available on other websites and do not facilitate or endorse illegal distribution of copyrighted materials.'
    },
    {
      title: 'Requests for Content Removal',
      icon: <Trash className="w-6 h-6" />,
      content: 'If you have any concerns or requests regarding the removal of content linked through our platform, please contact us with detailed information about your request. We will review each request and take necessary actions in compliance with legal requirements and our content policies. Please note that we can only address issues related to the links provided and not the content hosted on third-party websites.'
    },
    {
      title: 'Disclaimers',
      icon: <Siren className="w-6 h-6" />,
      content: 'At WatchAnimeHindi.in X AceAnime.online , we firmly oppose piracy and exclusively provide access to content that is already available on other websites. We do not host any files on our servers. Instead, our platform sources materials from independent third parties. We do not take responsibility for the content on these external websites, as we only index links that are publicly accessible online. For requests to remove content.'
    },
  ];

  return (
    <div className="bg-gray-900 text-gray-200 ">
      <HeaderNew />
      <main className="container mx-auto max-w-4xl px-4 py-8">
        <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-100">
          Privacy Policy
        </h1>
        <p className="text-xl text-center mb-12 text-gray-400 max-w-2xl mx-auto">
          We value your privacy and are committed to protecting your personal information. This policy outlines our practices regarding data collection and usage.
        </p>

        <div className="space-y-6">
          {policyItems.map((item, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-md overflow-hidden transition-all duration-300"
            >
              <button
                className="w-full px-6 py-4 flex items-center justify-between text-left focus:outline-none"
                onClick={() => setActiveSection(activeSection === index ? null : index)}
              >
                <div className="flex items-center">
                  <div className="p-2 bg-gray-700 rounded-full mr-4">
                    {item.icon}
                  </div>
                  <h2 className="text-xl font-semibold">{item.title}</h2>
                </div>
                <ArrowRight className={`w-5 h-5 transform transition-transform duration-300 ${activeSection === index ? 'rotate-90' : ''}`} />
              </button>
              <div
                className={`px-6 pb-4 transition-all duration-300 ${activeSection === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}
              >
                <p className="text-gray-300">{item.content}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">
            Have Questions?
          </h2>
          <p className="text-lg mb-6 text-gray-400 max-w-2xl mx-auto">
            If you have any questions about our privacy policy or how we handle your data, please don't hesitate to reach out to us.
          </p>
          <Link to="https://instagram.com/lastghostvisual">
            <button className="bg-gray-700 text-gray-200 px-8 py-3 rounded-full font-semibold hover:bg-gray-600 transition-colors duration-300 flex items-center mx-auto group">
              <Mail className="mr-2 w-5 h-5 group-hover:animate-bounce" />
              Contact Us
            </button>
          </Link>
          <div className='mt-4'>
            <p class name="text-gray-400">Rez ©2024 WatchAnimeHindi X AceAnime - All Rights Reserved</p>
          </div>
        </div>
      </main>
      <FooterNav />
    </div>
  );
};

export default MinimalistPrivacyPolicyPage;
