import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ItemsAdmin.css';

const App = () => {
  const [rezaniItems, setRezaniItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [newRezani, setNewRezani] = useState({
    src: '',
    alt: '',
    title: '',
    link: '',
    isRecommended: false,
    isMovie: false,
    isLatest: false
  });
  const [editItem, setEditItem] = useState(null); // State for edit item
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    axios.get('https://watchanimehindi.onrender.com/api/rezani')
      .then(response => {
        setRezaniItems(response.data);
        setFilteredItems(response.data); // Set initial filter to all items
      })
      .catch(err => setError('Failed to fetch rezani items'))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let items = rezaniItems;
    
    // Filter by selected category
    if (selectedFilter !== 'all') {
      items = items.filter(item => item[selectedFilter]);
    }
    
    // Filter by search term
    if (searchTerm) {
      items = items.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.alt.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    setFilteredItems(items);
  }, [selectedFilter, searchTerm, rezaniItems]);

  const handleInputChange = (e) => {
    setNewRezani({
      ...newRezani,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setNewRezani({
      ...newRezani,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (editItem) {
      // Update existing item
      axios.put(`https://watchanimehindi.onrender.com/api/rezani/${editItem._id}`, newRezani)
        .then(response => {
          const updatedItem = response.data;
          setRezaniItems(prevItems =>
            prevItems.map(item => item._id === updatedItem._id ? updatedItem : item)
          );
          setEditItem(null); // Clear edit mode
        })
        .catch(err => setError('Failed to update rezani item'))
        .finally(() => setLoading(false));
    } else {
      // Add new item
      axios.post('https://watchanimehindi.onrender.com/api/rezani', newRezani)
        .then(response => {
          setRezaniItems(prevItems => [...prevItems, response.data]);
          setNewRezani({ src: '', alt: '', title: '', link: '', isRecommended: false, isMovie: false, isLatest: false });
        })
        .catch(err => setError('Failed to add new rezani item'))
        .finally(() => setLoading(false));
    }
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setNewRezani(item); // Populate form with item data
  };

  const handleCancelEdit = () => {
    setEditItem(null);
    setNewRezani({ src: '', alt: '', title: '', link: '', isRecommended: false, isMovie: false, isLatest: false });
  };

  return (
    <div className="admin-dashboard-items">
      <h1>Admin Dashboard</h1>
      {error && <div className="error-message">{error}</div>}
      
      <div className="filter-buttons">
        <button
          className={`filter-button ${selectedFilter === 'all' ? 'active' : ''}`}
          onClick={() => setSelectedFilter('all')}
        >
          All
        </button>
        <button
          className={`filter-button ${selectedFilter === 'isRecommended' ? 'active' : ''}`}
          onClick={() => setSelectedFilter('isRecommended')}
        >
          Recommended
        </button>
        <button
          className={`filter-button ${selectedFilter === 'isMovie' ? 'active' : ''}`}
          onClick={() => setSelectedFilter('isMovie')}
        >
          Movies
        </button>
        <button
          className={`filter-button ${selectedFilter === 'isLatest' ? 'active' : ''}`}
          onClick={() => setSelectedFilter('isLatest')}
        >
          Latest
        </button>
      </div>
      
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        className="search-field"
      />
      
      <form onSubmit={handleSubmit} className="rezani-form">
        <input
          type="text"
          name="src"
          value={newRezani.src}
          onChange={handleInputChange}
          placeholder="Image URL"
          className="input-field"
        />
        <input
          type="text"
          name="alt"
          value={newRezani.alt}
          onChange={handleInputChange}
          placeholder="Alt Text"
          className="input-field"
        />
        <input
          type="text"
          name="title"
          value={newRezani.title}
          onChange={handleInputChange}
          placeholder="Title"
          className="input-field"
        />
        <input
          type="text"
          name="link"
          value={newRezani.link}
          onChange={handleInputChange}
          placeholder="Link"
          className="input-field"
        />
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="isRecommended"
            checked={newRezani.isRecommended}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
          Recommended
        </label>
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="isMovie"
            checked={newRezani.isMovie}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
          Movie
        </label>
        <label className="checkbox-label">
          <input
            type="checkbox"
            name="isLatest"
            checked={newRezani.isLatest}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
          Latest
        </label>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? (editItem ? 'Updating...' : 'Adding...') : (editItem ? 'Update Anime' : 'Add Anime')}
        </button>
        {editItem && (
          <button type="button" onClick={handleCancelEdit} className="cancel-button">
            Cancel
          </button>
        )}
      </form>

      <h2>Anime Items</h2>
      <ul className="rezani-list">
        {filteredItems.map(item => (
          <li
            key={item._id}
            className={`rezani-item ${item.isRecommended ? 'recommended-items' : item.isMovie ? 'movie' : item.isLatest ? 'latest' : ''}`}
          >
            <img src={item.src} alt={item.alt} className="rezani-image" />
            <div className="rezani-details">
              <h2 className="rezani-title">{item.title}</h2>
              <p className="rezani-link">{item.link}</p>
              <button
                onClick={() => handleEdit(item)}
                className="edit-button"
              >
                Edit
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
