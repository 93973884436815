
import React from 'react';
import NewHome from "./NewHome.js";

export default function Home() {
  return (
    <div>
      <NewHome/>
    </div>
  );
}