import { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Play, Info, Search, Bell, Home, Compass, Heart, User, X, CheckCircle, AlertTriangle, BellOff } from 'lucide-react';
import axios from 'axios';
import '../home/NewHome.css';
import { Link } from 'react-router-dom';
import novideo from "../../images/notvideo.png"
import { useAnime } from '../NewComp/AnimeContext';
import BannerAd from '../utils/aceAdBanner';



const notifications = [
  {
    id: 1,
    type: 'success',
    message: 'New Update now latest sort by date (mm/dd/yyyy).',
  },
  {
    id: 1,
    type: 'success',
    message: 'New Ui is not ready fully  & API is working.',
  },
  {
    id: 3,
    type: 'info',
    message: 'We Changed Our Domain to watchanimehindi.in.',
  }

];

export default function NewHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);  // Track selected genre
  const { animeData, loading } = useAnime();
  const [error, setError] = useState(null);

  const [notificationList, setNotificationList] = useState(notifications);

  const handleDelete = (id) => {
    setNotificationList(notificationList.filter(notification => notification.id !== id));
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle className="w-6 h-6 text-green-500" />;
      case 'error':
        return <AlertTriangle className="w-6 h-6 text-red-500" />;
      case 'info':
        return <Bell className="w-6 h-6 text-blue-500" />;
      case 'warning':
        return <AlertTriangle className="w-6 h-6 text-yellow-500" />;
      default:
        return <Bell className="w-6 h-6 text-gray-500" />;
    }
  };

  const sectionRefs = {
    latest: useRef(null),
    recommended: useRef(null),
    movies: useRef(null)
  };



  

  const filterAnime = (animeList) => {
    return animeList.filter(anime =>
      anime.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      anime.alt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Map episode count from anime data to anime lists
  const addEpisodesToAnime = (animeList) => {
    return animeList.map(anime => {
      const animeDetail = animeData.animeDetails.find(a => a.animeUrl === anime.link);
      return {
        ...anime,
        genre: animeDetail ? animeDetail.genre : 'Unknown',
        totalEpisodes: animeDetail ? animeDetail.totalEpisodes : 'Unknown'
      };
    });
  };

  const moviesWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.movies || []), [animeData.movies]);
  const latestWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.latest || []).reverse(), [animeData.latest]);
  const recommendedWithEpisodes = useMemo(() => addEpisodesToAnime(animeData.recommended || []), [animeData.recommended]);
  const searchResultsWithEpisodes = useMemo(() => addEpisodesToAnime(filterAnime(animeData.allAnime || [])), [animeData.allAnime, searchQuery]);

  const handleScroll = (section, direction) => {
    const ref = sectionRefs[section].current;
    if (ref) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      ref.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const filterByGenre = (animeList, genre) => {
    if (!genre) return animeList; // Return all if no genre is selected
    return animeList.filter(anime => anime.genre?.toLowerCase().includes(genre.toLowerCase()));
  };


  const renderAnimeGrid = (animeList, section) => (
    <div className="relative">
      <div className="flex space-x-4 overflow-x-auto scrollbar-style" ref={sectionRefs[section]}>
        {animeList.map((anime) => (
          <div key={anime._id} className="min-w-[210px] rounded-lg overflow-hidden shadow-md">
            <Link key={anime.link} to={`/${anime.link}`} >
              <img
                src={anime.src}
                alt={anime.alt}
                className="w-full h-[270px] object-cover"
              />
              {anime.totalEpisodes !== undefined && (
                <p className="relative text-xs text-gray-300 bottom-[25px] bg-red-500 rounded-full px-2 mr-2 float-right">Ep: {anime.totalEpisodes}</p>
              )}
              <div className="p-4 bg-black h-[65px] bg-opacity-40">
                <h3 className="text-xs font-bold">{anime.title}</h3>
                {/* Display total episodes */}

              </div>
            </Link>
          </div>
        ))}
      </div>

    </div>
  );

  return (
    <>
      <header className="p-4 flex justify-between items-center sticky top-0 bg-gray-900 z-10">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text">
            <Link to="/">
              WatchAnimeHindi
            </Link>
          </h1>
        </div>
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="hover:text-pink-500">Home</Link>
          <Link to="/explore" className="hover:text-pink-500">Explore</Link>
          <Link to="/donate" className="hover:text-pink-500">Donate</Link>
          <Link to="/policy" className="hover:text-pink-500">Policy & Privacy</Link>
        </nav>
        <div className="flex items-center space-x-4">
          <button onClick={() => setIsNotificationVisible(!isNotificationVisible)}>
            <Bell className={`w-6 h-6 cursor-pointer ${isNotificationVisible ? 'hidden' : 'block'}`} />
            <BellOff className={`w-6 h-6 cursor-pointer ${isNotificationVisible ? 'block' : 'hidden'}`} />
          </button>
          {/* <Bell className="w-6 h-6 cursor-pointer" /> */}
          <button onClick={() => setIsSearchVisible(!isSearchVisible)}>
            <Search className={`w-6 h-6 cursor-pointer ${isSearchVisible ? 'hidden' : 'block'}`} />
            <X className={`w-6 h-6 cursor-pointer ${isSearchVisible ? 'block' : 'hidden'}`} />
          </button>
          {/* <div className="w-8 h-8 bg-gray-700 rounded-full">
            <img
              src="https://i.pravatar.cc/300"
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </div> */}
        </div>
      </header>

      <div className={`p-4 bg-gray-800 sticky top-[54px] z-10 ${isSearchVisible ? 'block' : 'hidden'}`}>
        <input
          type="text"
          placeholder="Search anime, movies..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-pink-500"
        />
        

      </div>
      <div className={`notify-style p-4 fixed top-[40px] z-10 w-[300px] right-0 md:w-[450px] ${isNotificationVisible ? 'block' : 'hidden'}`}>
        <div className="p-0">
          <div className="max-w-2xl mx-auto bg-black shadow-lg rounded-lg shadow shadow-gray-900 ">
            <div className="p-3 border-b border-gray-700">
              <h1 className="text-sm font-bold text-gray-00 md:text-2xl">Dev Notifications</h1>
            </div>
            <ul className="divide-y divide-gray-900 divide-opacity-10">
              {notificationList.map((notification) => (
                <li key={notification.id} className={`flex items-center p-4 ${notification.type === 'success' ? 'bg-green-black' : notification.type === 'error' ? 'bg-red-black' : notification.type === 'info' ? 'bg-blue-black' : 'bg-yellow-black'}`}>
                  <div className="flex-shrink-0 ">
                    {renderIcon(notification.type)}
                  </div>
                  <div className="ml-4 flex-1">
                    <p className={`text-xs font-medium md:text-sm ${notification.type === 'success' ? 'text-green-800' : notification.type === 'error' ? 'text-red-800' : notification.type === 'info' ? 'text-blue-800' : 'text-yellow-800'}`}>
                      {notification.message}
                    </p>
                  </div>
                  <button
                    onClick={() => handleDelete(notification.id)}
                    className="text-gray-500 hover:text-gray-700 ml-4"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
      </div>

      <main className={`px-4 sticky top-[116px] z-10 bg-gray-800 shadow shadow-gray-800 ${isSearchVisible ? 'block' : 'hidden'}`}>
        {searchQuery && (
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              Search Results for "{searchQuery}"
            </h2>
            {loading ? (
              <p>Loading results...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              renderAnimeGrid(searchResultsWithEpisodes, 'search')
            )}

            {searchResultsWithEpisodes.length === 0 && (
              <img src={novideo} alt="Empty" className=" mx-auto h-[90px] " />
            )}
          <BannerAd/>
          </section>
        )}
      </main>
    </>
  )


}